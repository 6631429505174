
class Sandbox {

    BOOKING_CREATE_ERROR = {
        "FW00010" : "Une erreur s'est produite",
        "FW00011" : "Une erreur s'est produite",
        "FW00012" : "Ce véhicule n'est plus disponible.",
        "FW00013" : "Ce véhicule n'est plus disponible.",
        "FW00014" : "Cette réservation n'est plus disponible.",
    }

    constructor() {

    }

    subString = (string, nbChars = 10, suffix="...") => {
        if (string?.length > nbChars) {
            return string.substring(0, nbChars) + suffix
        } else {
            return string
        }
    }

    range = (start, stop, step) => {
        if (typeof stop == 'undefined') {
            // one param defined
            stop = start;
            start = 0;
        }

        if (typeof step == 'undefined') {
            step = 1;
        }

        if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
            return [];
        }

        const result = [];
        for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
            result.push(i);
        }

        return result;
    };

    numberPad = (number, pad) => {
        let s = String(number);
        while (s.length < (pad || 2)) {s = "0" + s;}
        return s;
    }

    /**
     * Function to sort alphabetically an array of objects by some specific key.
     *
     * @param {String} property Key of the object to sort.
     */
    dynamicSort = (property) => {
        let sortOrder = 1;

        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) {
            if(sortOrder === -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }
        }
    }

    shortenString = (str, length) => {
        if (str?.length > length) {
            return str.substring(0, length) + '...'
        } else {
            return str
        }
    }

    roundLikePHP = (num, dec) => {
        const num_sign = num >= 0 ? 1 : -1;
        return parseFloat((Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec));
    }
    getLocalDayName = (num) => {
        return localeFr.localize.day(num, { width: 'wide' })
    }

    getLocalMonthName = (num) => {
        return localeFr.localize.month(num, { width: 'wide' })
    }
    generateHours = (minuteStep = 60) => {
        let result = [];
        const hourArray = sandbox.range(0,24,1)
        hourArray.map((hours) => {
            if(minuteStep === 60) {
                result.push(`${sandbox.numberPad(hours,2)}:00`)
            } else if(minuteStep) {
                const minuteArray = sandbox.range(0,60,minuteStep)
                minuteArray.map((minute) => {
                    result.push(`${sandbox.numberPad(hours,2)}:${sandbox.numberPad(minute,2)}`)
                })
            }
        })
        return result;
    }

    deepClone = (object) => {
        return JSON.parse(JSON.stringify(object))
    }
    getAgencyName = (agencies, agencyId) => {
        const agency = agencies?.find(item => parseInt(item?.id) === parseInt(agencyId))
        if(agency?.public_name && agency?.public_name !== '' ) {
            return agency?.public_name
        } else if(agency?.name) {
            return agency?.name
        } else {
            return ""
        }
    }
    inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    humanFileSize = (bytes, si=false, dp=1) => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['Ko', 'Mo']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }
}
const sandbox = new Sandbox();

export default sandbox ;