import jsonToFormData from "json-form-data"

class ApiService {
    controller = undefined;
    constructor() {}

    async get(url, data = {}, allowAbort = true) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let requestOptions = {
            method: "GET"
        }
        if (allowAbort) {
            this.controller?.abort("aborting previous request");
            this.controller = new AbortController();
            requestOptions = {
                headers,
                signal: this.controller.signal,
                ...requestOptions
            }
        } else {
            requestOptions = {
                headers,
                ...requestOptions
            }
        }

        return fetch(url + '?' + new URLSearchParams(data),
            requestOptions
        ).then((response) =>
            response.json()
        ).then( (json) => {
            console.log("result", json);
            this.controller = undefined;
            return json
        })
    }

    async post(url, data = null, allowAbort = true) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let requestOptions = {
            method: "POST",
            body: JSON.stringify(data)
        }
        
        if (allowAbort) {
            this.controller?.abort("aborting previous request");
            this.controller = new AbortController();
            requestOptions = {
                headers,
                signal: this.controller.signal,
                ...requestOptions
            }
        } else {
            requestOptions = {
                headers,
                ...requestOptions
            }
        }

        return fetch(url, 
            requestOptions
        ).then((response) =>
            response.json()
        ).then( (json) => {
            console.log("result", json);
            this.controller = undefined;
            return json;
        })
    }

    async postFormData(url, data = {}, allowAbort = true) {
        const headers = {
            'Accept': 'application/json',
            // 'Content-Type': 'multipart/form-data'
        }
        let formData = jsonToFormData(data)
        // console.log(data)
        // let formData = new FormData();
        //
        // Object.keys(data).map( (name) => {
        //     formData.append(name, data[name]);
        // })
        let requestOptions = {
            method: "POST",
            body: formData
        }
        
        if (allowAbort) {
            this.controller?.abort("aborting previous request");
            this.controller = new AbortController();
            requestOptions = {
                headers,
                signal: this.controller.signal,
                ...requestOptions
            }
        } else {
            requestOptions = {
                headers,
                ...requestOptions
            }
        }

        return fetch(url, 
            requestOptions
        ).then((response) =>
            response.json()
        ).then( (json) => {
            console.log("result", json);
            this.controller = undefined;
            return json;
        })
    }
    async delete(url, data = {}, allowAbort = true) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let requestOptions = {
            method: "DELETE"
        }

        if (allowAbort) {
            this.controller?.abort("aborting previous request");
            this.controller = new AbortController();
            requestOptions = {
                headers,
                signal: this.controller.signal,
                ...requestOptions
            }
        } else {
            requestOptions = {
                headers,
                ...requestOptions
            }
        }

        return fetch(url + '?' + new URLSearchParams(data),
            requestOptions
        ).then((response) =>
            response.json()
        ).then( (json) => {
            console.log("result", json);
            this.controller = undefined;
            return json
        })
    }
}
const apiService = new ApiService();
export default apiService;

